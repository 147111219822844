import React from 'react';
import { DatePicker, Space } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;
interface IProps {
    onChange: (dates: any, dateStrings: any) => void
    disabledDate?: (current: any) => any
}
export default function DatePickerCustom({ onChange, disabledDate }: IProps) {
    return (
        <>
            <RangePicker
                defaultValue={[moment(new Date().toString()),
                moment(new Date().toString())]}
                ranges={{
                    Today: [moment(), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                }}
                onChange={onChange}
                disabledDate={disabledDate || undefined}
            />
        </>
    );
}
