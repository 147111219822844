import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

function onChange(value: any) {
}



function onSearch(val: any) {
  
}
interface IProps {
  onChange: (name: string, value: any) => void,
  onSearch: (val: any) => void,
  placeholder?: string,
  data: any[],
  label: string,
  valueLabel: string
  label2?: string
  label3?: string
  defaultVal?: string
  disabled?: boolean
}

const SelectSearchInput = ({ onChange, onSearch, placeholder, data, label, valueLabel, label2, label3, defaultVal, disabled }: IProps) => {
  return <>
    <Select
      disabled={disabled ? true : false}
      showSearch
      defaultValue={defaultVal}
      placeholder={placeholder || 'selectionner...'}
      optionFilterProp="children"
      onChange={onChange}
      onSearch={onSearch}
      filterOption={(input, option: any) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {
        data.map(item => {
          return (
            <Option key={item.id} value={item[`${valueLabel}`]}>
              {
                (label2 || label3) ?
                  item[`${label}`] + ' ' + item[`${label2}`] + ' ' + item[`${label3}`]
                  : item[`${label}`]
              }
            </Option>
          )
        })
      }
    </Select>
  </>;
}

export default SelectSearchInput;