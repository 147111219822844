import React from 'react';

import { Table } from 'antd'
interface IProps {
  columns: any[],
  dataSource: any[]
  loading?: boolean
}
const Tableau = ({ columns, dataSource, loading }: IProps) => {
  return <>
    <Table
      pagination={false}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
    />
  </>;
}

export default Tableau;