import * as React from 'react';
import { Table, Card, Form, Row, Col, Select, Tag, InputNumber, Button, Popconfirm, Spin } from 'antd';
import { Link } from 'react-router-dom'
import axios from '../../../services/axios'
import SelectSearchInput from '../../../utils/components/selectSearchInput';
import DatePickerCustom from '../../../utils/components/datePicker'
import { BASE_URL_API} from "../../../utils/constants"
import moment from 'moment'
import { LoadingOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

const { Option } = Select;
interface IProps {
  pagination: {
    pageSize: Number,
    current: Number
  }
}
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Index = () => {


  const [form] = Form.useForm();
  const [data, setData] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 100
  });
  const [loading, setLoading] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [devises, setDevises] = React.useState([])
  const [idDevise, setIdDevise] = React.useState(null)
  const [sumByDevise, setSumByDevise] = React.useState({})
  const [dateStart, setDateStart] = React.useState(moment(new Date()).format('YYYY-MM-DD'));
  const [dateEnd, setDateEnd] = React.useState(moment(new Date()).format('YYYY-MM-DD'));

  React.useEffect(() => {
    setLoading(true);
    const dateJour = new Date();
    const dateFormated = moment(dateJour).format('YYYY-MM-DD');

    fetchData({ pagination } as IProps);
    getDevise()
    getSumBySortieDeviseEntre(dateFormated, dateFormated)
  }, [])

  const renderCustomCell = (item: any, name: string) => {
    if (name == 'client') {
      const { client } = item;
      const clients = client.prenom + ' ' + client.nom + '/' + client.telephone
      return clients;
    }
    else if (name == 'deviseentre') {
      const { deviseentre } = item
      return deviseentre.code
    }
    else if (name == 'devisesortie') {
      const { devisesortie } = item
      return devisesortie.code
    }
  };
  let i = 0;
  const columns = [
    {
      title: "N°",
      key: "index",
      render: (row: any, value: any, index: number = 0) => (
        <>
          {index + 1}
        </>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date_facture',
      key: 'date_facture',
    },
    {
      title: 'N° reçu',
      dataIndex: 'numero_recu',
      key: 'numero_recu'
    },
    {
      title: 'Client',
      key: 'client',
      render: (record: any,) => renderCustomCell(record, 'client'),
    },
    {
      title: 'Réceveur',
      key: 'nom_deposant',
      render: (nom_deposant: number, row: any) => (
        <>
          {row.nom_deposant ? (row.nom_deposant + ' ' + row.tel_deposant) : 'Par le client lui méme'}
        </>
      ),
    },
    {
      title: 'Motif',
      dataIndex: 'motif',
      key: 'motif',
    },
    {
      title: 'Code',
      dataIndex: 'code_facture',
      align: 'center',
      key: 'code_facture',
    },
    {
      title: 'Montant Entré ',
      dataIndex: 'montant_entre',
      align: 'right',
      key: 'montant_entre',
      render: (montant: any, row: any) => (
        <>
          <Tag >
            {parseFloat(montant).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ' + row.deviseentre.code}
          </Tag>
        </>
      ),
    },
    {
      title: 'Taux',
      dataIndex: 'taux',
      align: 'center',
      key: 'taux',
      render: (montant: any, row: any) => (
        <>
          <Tag >
            {parseFloat(montant).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          </Tag>
        </>
      ),

    },
    {
      title: 'Montant Sortie',
      dataIndex: 'montant_sortie',
      align: 'right',
      key: 'montant_sortie',
      render: (montant: any, row: any) => (
        <>
          <Tag color='success' >
            {parseFloat(montant).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ' + row.devisesortie.code}
          </Tag>
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: number) => (
        <>
          {
            status == 1 ? (
              <Tag color='success' >
                Payé
              </Tag>
            ) : (
              <Tag color='warning' >
                en attente
              </Tag>
            )
          }
        </>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: any, row: any) => (
        <>
          <Button title="Editer"> <Link style={{ marginRight: 10 }} to={`/factures-sorties/edit/${id}`}><EditOutlined /></Link></Button>
          <Popconfirm
            disabled={row.status !== 1 ? false : true}
            title="Etes vous sûre de supprimer cette ligne?"
            onConfirm={() => handleOk(id)}
            okText={(<><Spin spinning={confirmLoading} indicator={antIcon} />     OUI </>)}
            cancelText='Non'
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleCancel}
          >
            <Button title="supprimer" type="primary" danger ><DeleteOutlined /></Button>
          </Popconfirm>
        </>
      )
    },

  ];

  const getRandomuserParams = (params: any) => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
  });


  const onChange = (name: string, value: any) => {
    form.setFieldsValue({ [name]: value })
    setIdDevise(value);
    setLoading(true)
    axios.get(`factures/type/sortie-by-devise/${dateStart}/${dateEnd}/${value}`)
      .then(response => {
        
        setData(response.data)
        setLoading(false)
      });
  }
  function onSearch(val: string) {
    
  }

  const handleCancel = () => {
    console.log('Clicked cancel button');
  };

  const handleOk = (id: number) => {
    
    const dateJour = new Date();
    const dateFormated = moment(dateJour).format('YYYY-MM-DD');
    setLoading(true)
    axios.delete(`/factures/delete/${id}`)
      .then(function (response) {
        
        axios.get(`/factures/type/entree/${dateStart}/${dateEnd}`)
          .then(response => {
            // const dataPagination = getRandomuserParams(params)
            
            getSumBySortieDeviseEntre(dateStart, dateEnd)
            setData(response.data)
            setLoading(false)
            // setPagination({ ...dataPagination.pagination, total: 20 })
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    fetchData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  const getDevise = () => {
    axios.get('/devises')
      .then(response => {
        
        setDevises(response.data);
        setLoading(false)
      });
  }

  const getSumBySortieDeviseEntre = (start: any, end: any) => {
    axios.get(`/factures/rapport/sommes-out/by-devise/${start}/${end}`)
      .then(response => {
        setSumByDevise(response.data);
        
        setLoading(false)
      });
  }


  const fetchData = (params: any) => {
    setLoading(true);
    const dateJour = new Date();
    const dateFormated = moment(dateJour).format('YYYY-MM-DD');
    axios.get(`/factures/type/sortie/${dateFormated}/${dateFormated}`)
      .then(response => {
        const dataPagination = getRandomuserParams(params)
        
        setData(response.data)
        setLoading(false)
        setPagination({ ...dataPagination.pagination, total: 20 })
      });
  };
  const onDateChange = (dates: any, dateStrings: any) => {
    setLoading(true)
    setDateStart(dateStrings[0])
    setDateEnd(dateStrings[1])
    
    if (idDevise) {
      axios.get(`factures/type/sortie-by-devise/${dateStrings[0]}/${dateStrings[1]}/${idDevise}`)
        .then(response => {
          
          setData(response.data)
          setLoading(false)
        });
    }
    else {
      axios.get(`factures/type/sortie/${dateStrings[0]}/${dateStrings[1]}`)
        .then(response => {
          
          setData(response.data)
          setLoading(false)
        });
    }

    getSumBySortieDeviseEntre(dateStrings[0], dateStrings[1])
  }

  return <>
    <Card size="small" title={<h4>Liste des factures de sortie</h4>} extra={<Link to="/factures/add-sorties" className="btn btn-primary">Nouveau</Link>} >
      <Row justify='end'>
        <Form form={form} layout='inline'>
          <Form.Item label="Dates" >
            <DatePickerCustom
              onChange={onDateChange}
            />
          </Form.Item>
          <Form.Item label='Devise'>
            <SelectSearchInput
              onChange={(v) => onChange('devise', v)}
              onSearch={(value) => onSearch(value)}
              data={devises}
              label='code'
              valueLabel='id'
              placeholder="Selectionnez la devise d'entre"
            />
          </Form.Item>
        </Form>
        {/* <Col>
          <a target="_bland"
            href={`${BASE_URL_API}/factures/ventes/${dateStart}/${dateEnd}`} className="btn btn-warning">Imprimer
          </a>
        </Col> */}
      </Row>
      <br />
      <br />
      <Table
        columns={columns as any}
        rowKey={(record: any) => record.login}
        dataSource={data}
        pagination={{ pageSize: 100 }}
        loading={loading}
      // onChange={handleTableChange}
      />

      <Row justify='end'>
        {
          Object.entries(sumByDevise).map(
            (val, index) => <Col span={4}>
              <Form.Item label={`Total en ${val[0]}`} >
                <InputNumber
                  disabled
                  value={val[1] as number}
                  style={{ width: '100%', fontSize: 15 }}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
          )
        }
      </Row>
    </Card>
  </>;
}

export default Index;