import React from 'react';
import { Card, Row, Col } from 'antd';
import { Bar, Pie, Doughnut } from 'react-chartjs-2';
import axios from '../services/axios';

export default function Dashboard() {
    const [labelClient, setLabelClient] = React.useState([]);
    const [nbreClient, setNbreClient] = React.useState([]);

    const [labelTrans, setLabelTrans] = React.useState([]);
    const [nbreTrans, setNbreTrans] = React.useState([]);

    const [nbreDetteCredit, setNbreDetteCredit] = React.useState([]);

    React.useEffect(() => {
        getCountClient();
        getCountTransactionDevise();
        getCountCreditDettes();
    }, [])

    const getCountClient = () => {
        axios.get('/rapports/top-performance')
            .then((response: any) => {
                
                setLabelClient(response.data[0]);
                setNbreClient(response.data[1]);
                //    setLoading(false)
            });
    }

    const getCountTransactionDevise = () => {
        axios.get('/rapports/transactions-by-devise')
            .then((response: any) => {
                
                setLabelTrans(response.data[0]);
                setNbreTrans(response.data[1]);
                //    setLoading(false)
            });
    }

    const getCountCreditDettes = () => {
        axios.get('/rapports/credit-Dettes')
            .then((response: any) => {
                
                setNbreDetteCredit(response.data);
                //    setLoading(false)
            });
    }

    const data = {
        labels: labelClient,
        datasets: [
            {
                label: 'Performance clients',
                data: nbreClient,
                backgroundColor: [
                    'rgba(187, 143, 206, 0.6',
                    'rgba(37,139,60, 0.6)',
                    'rgba(0,123,255, 0.6)',
                    'rgba(84, 153, 199, 1)',
                    'rgba(110, 44, 0, 1)',
                    'rgba(255,99,132, 1)',
                ],
                borderColor: [
                    'rgba(187, 143, 206, 0.6)',
                    'rgba(37,139,60, 0.6)',
                    'rgba(0,123,255, 0.6)',
                    'rgba(84, 153, 199, 1)',
                    'rgba(110, 44, 0 1)',
                    'rgba(255,99,132, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const nbreTransDevise = {
        labels: labelTrans,
        datasets: [
            {
                label: 'Nombre de transaction par devise',
                data: nbreTrans,
                backgroundColor: [
                    'rgba(187, 143, 206, 0.6)',
                    'rgba(37,139,60, 0.6)',
                    'rgba(0,123,255, 0.6)',
                    'rgba(84, 153, 199, 1)',
                    'rgba(110, 44, 0,1)',
                    'rgba(255,99,132, 1)',
                ],
                borderColor: [
                    'rgba(187, 143, 206, 0.6)',
                    'rgba(37,139,60, 0.6)',
                    'rgba(0,123,255, 0.6)',
                    'rgba(84, 153, 199, 1)',
                    'rgba(110, 44, 0, 1)',
                    'rgba(255,99,132, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };



    const nbreCredit = {
        labels: ['Dettes', 'Crédits'],
        datasets: [
            {
                label: 'Les Dettes et crédits',
                data: nbreDetteCredit,
                backgroundColor: [
                    'rgba(187, 143, 206, 0.6)',
                    'rgba(37,139,60, 0.6)',
                    'rgba(0,123,255, 0.6)',
                    'rgba(84, 153, 199, 1)',
                    'rgba(110, 44, 0, 1)',
                    'rgba(255,99,132, 1)',
                ],
                borderColor: [
                    'rgba(187, 143, 206, 0.6)',
                    'rgba(37,139,60, 0.6)',
                    'rgba(0,123,255, 0.6)',
                    'rgba(84, 153, 199, 1)',
                    'rgba(110, 44, 0 1)',
                    'rgba(255,99,132, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };
    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={12} >
                    <Card type="inner" title="Top 10 des clients les plus performants" extra={<a href="#"></a>}>
                        <Bar data={data} options={options} />
                    </Card>
                </Col>
                <Col span={12} >
                    <Card type="inner" title="Transactions par devise" extra={<a href="#"></a>}>
                        <Bar data={nbreTransDevise} options={options} />
                    </Card>
                </Col>
                <Col span={12} >
                    <Card type="inner" title="Crédits par devise" extra={<a href="#"></a>}>
                        <Pie data={nbreCredit} />
                    </Card>
                </Col>
                <Col span={12} >
                    <Card type="inner" title="Dettes par devise" extra={<a href="#"></a>}>
                        <Doughnut data={data} />
                    </Card>
                </Col>
            </Row>

        </>
    );
}
