import React from 'react';
import { Table, Tag, Space, Card, Popconfirm, Spin, Row, Col, Form, DatePicker, Select, InputNumber, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import axios from '../services/axios'
import SelectSearchInput from '../utils/components/selectSearchInput';
import DatePickerCustom from '../utils/components/datePicker';
import moment from 'moment';
import { BASE_URL_API } from '../utils/constants';

const { RangePicker } = DatePicker;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface IProps {
  pagination: {
    pageSize: Number,
    current: Number
  }
}

const Rapport = () => {
  const [dataCredit, setDataCredit] = React.useState([]);
  const [dataDette, setDataDette] = React.useState([]);
  const [redirect, setRedirect] = React.useState(false)
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 100
  });
  const [loading, setLoading] = React.useState(false);
  const [devises, setDevises] = React.useState([])
  const [clients, setClients] = React.useState([])
  const [sumDettes, setSumDettes] = React.useState({})
  const [sumCredits, setSumCredits] = React.useState({})
  const [idDevise, setIdDevise] = React.useState()
  const [idClient, setIdClient] = React.useState()
  const [dispoNet, setDispoNet] = React.useState({})
  const [dispoCaisse, setDispoCaisse] = React.useState({})

  const [solde, setSolde] = React.useState([])
  const [montantTotalGNF, setMontantTotalGNF] = React.useState(0)

  const [form] = Form.useForm();

  React.useEffect(() => {
    const dateJour = new Date();
    const dateFormated = moment(dateJour).format('YYYY-MM-DD');
    getDevise()
    fetchDataCredit({ pagination } as IProps);
    fetchDataDettes({ pagination } as IProps);
    getSumCredits()
    getSumDettes()
    getClient()
  }, [])

  const getRandomuserParams = (params: any) => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
  });
  const handleTableDettesChange = (pagination: any, filters: any, sorter: any) => {
    fetchDataDettes({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };
  const getDevise = () => {
    axios.get('/devises')
      .then(response => {
        
        setDevises(response.data);
        setLoading(false)
      });
  }
  const getClient = () => {
    axios.get('/clients')
      .then(response => {
        
        setClients(response.data);
        setLoading(false)
      });
  }

  const handleTableCreditsChange = (pagination: any, filters: any, sorter: any) => {
    fetchDataCredit({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  const fetchDataCredit = (params: any) => {
    setLoading(true);
    const dateJour = new Date();
    const dateFormated = moment(dateJour).format('YYYY-MM-DD');
    axios.get(`/comptes/credits/entreprise-to-client`)
      .then(response => {
        // const dataPagination = getRandomuserParams(params)
        
        setDataCredit(response.data)
        setLoading(false)
        // setPagination({ ...dataPagination.pagination, total: 20 })
      });
  };

  const fetchDataDettes = (params: any) => {
    setLoading(true);
    const dateJour = new Date();
    const dateFormated = moment(dateJour).format('YYYY-MM-DD');
    axios.get(`/comptes/credits/client-to-entreprise`)
      .then(response => {
        // const dataPagination = getRandomuserParams(params)
        
        setDataDette(response.data)
        setLoading(false)
        // setPagination({ ...dataPagination.pagination, total: 20 })
      });
  };

  const getSumCredits = () => {
    axios.get(`/comptes/credits/sum`)
      .then(response => {
        
        setSumCredits(response.data);
        setLoading(false)
      });
  }

  const getSumDettes = () => {
    axios.get(`/comptes/dettes/sum`)
      .then(response => {
        
        setSumDettes(response.data);
        axios.get(`/comptes/credits/sum`)
          .then(res => {
            setDispoNet(sumObjectsByKey(res.data, response.data))
            getSumCaisse(sumObjectsByKey(res.data, response.data))
            setLoading(false)
          });
        setLoading(false)
      });
  }

  const getCompteEntreprise = () => {
    axios.get('/comptes_entreprises/soldes')
      .then(response => {
        
        setLoading(false)
        setSolde(response.data);

      });
  }

  const getSumCaisse = (dispo:any) => {
    const net = dispoNet as any;
    const gnf = net.GNF;
    const usd = net.USD;
    const euro = net.Euro;
    const fcfa = net.FCFA;
    axios.get('/comptes_entreprises/soldes')
      .then(response => {
        
        setLoading(false)
        let soldes: any = {};
        for (let index = 0; index < response.data.length; index++) {
          const data = response.data;
          soldes[data[index].devise.code] = data[index].solde;
        }
        const resultSum = sumObjectsByKeyNegatif(soldes,dispo).GNF;
        axios.get(`/rapports/montant-total-gnf/${sumObjectsByKeyNegatif(soldes,dispo).GNF}/${sumObjectsByKeyNegatif(soldes,dispo).USD}/${sumObjectsByKeyNegatif(soldes,dispo).Euro}/${sumObjectsByKeyNegatif(soldes,dispo).FCFA}`)
          .then(response => {
            
            setMontantTotalGNF(response.data);
        });
        setDispoCaisse(sumObjectsByKeyNegatif(soldes,dispo));

      });
  }

  const getSumDispoNet = () => {
    if (sumCredits && sumDettes) {
      setDispoNet(sumObjectsByKey(sumCredits, sumDettes));
    }
  }

  const onChange = (name: string, value: any) => {
    setLoading(true)
    form.setFieldsValue({ [name]: value })
    if (name == 'client') {
      setIdClient(value)
      axios.get(`/comptes/credits/sum/by-client/${value}`)
        .then(response => {
          
          setSumCredits(response.data);
          setLoading(false)
        });
      axios.get(`/comptes/dettes/sum/by-client/${value}`)
        .then(response => {
          
          setSumDettes(response.data);
          setLoading(false)
        });

      if (idDevise) {
        axios.get(`/comptes/credits/clients-devises/${value}/${idDevise}`)
          .then(response => {
            
            setDataCredit(response.data)
            setLoading(false)
          });
        axios.get(`/comptes/credits/clients-devises/${value}/${idDevise}`)
          .then(response => {
            
            setDataDette(response.data)
            setLoading(false)
          });
      }
      else {
        axios.get(`/comptes/credits/clients/${value}`)
          .then(response => {
            
            setDataCredit(response.data)
            setLoading(false)
          });
        axios.get(`/comptes/dettes/clients/${value}`)
          .then(response => {
            
            setDataDette(response.data)
            setLoading(false)
          });
      }

    }
    else if (name == "devise") {
      setIdDevise(value)
      if (idClient) {
        axios.get(`/comptes/credits/clients-devises/${idClient}/${value}`)
          .then(response => {
            
            setDataCredit(response.data)
            setLoading(false)
          });
        axios.get(`/comptes/dettes/clients-devises/${idClient}/${value}`)
          .then(response => {
            
            setDataDette(response.data)
            setLoading(false)
          });
      }
      else {
        axios.get(`/comptes/credits/devises/${value}`)
          .then(response => {
            
            setDataCredit(response.data)
            setLoading(false)
          });

        axios.get(`/comptes/dettes/devises/${value}`)
          .then(response => {
            
            setDataDette(response.data)
            setLoading(false)
          });
      }

    }


  }
  function onSearch(val: string) {
    
  }

  const renderCustomCell = (item: any, name: string) => {
    if (name == 'client') {
      const { client } = item;
      const clients = client.prenom + ' ' + client.nom + '/' + client.telephone
      return clients;
    }
    else if (name == 'devise') {
      const { devise } = item
      return devise.code
    }
  };

  let i = 0;
  const columns = [
    {
      title: "N°",
      key: "index",
      render: (row: any, value: any, index: number = 0) => (
        <>
          {index + 1}
        </>
      ),
    },
    {
      title: 'Client',
      key: 'prenom',
      render: (record: any,) => record.prenom + ' ' + record.nom + ' / ' + record.telephone
    },
    {
      title: 'Montant',
      dataIndex: 'solde',
      align: 'right',
      key: 'solde',
      render: (montant: any, record: any) => (
        <>
          <Tag >
            {parseFloat(montant).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ' + record.code}
          </Tag>
          { }
        </>
      ),

    },

  ];

  function sumObjectsByKey(ob1: any, ob2: any) {
    let sum: any = {};
    Object.keys(ob1).forEach(key => {
      if (ob2.hasOwnProperty(key)) {
        sum[key] = +ob1[key] + +ob2[key]
      }
    })
    return sum;
  }

  function sumObjectsByKeyNegatif(ob1: any, ob2: any) {
    let sum: any = {};
    Object.keys(ob1).forEach(key => {
      if (ob2.hasOwnProperty(key)) {
        sum[key] = +ob1[key] - +ob2[key]
      }
    })
    return sum;
  }


  return <>
    <Card size="small" title={<h4>les rapports des crédit et dettes des clients</h4>}
      extra={<a  target='_blank'
        href={idClient && idDevise ? `/rapports/rapport-credit-pdf/${idClient}/${idDevise}` :
          idClient ? `/rapports/rapport-credit-pdf/by-client/${idClient}` :
            idDevise ? `/rapports/rapport-credit-pdf/by-devise/undefined/${idDevise}` :
              `/rapports/rapport-credit-pdf`} className="btn btn-primary">Imprimer </a>}>
      <Row justify='end'>
        <Form layout='inline'>
          <Form.Item label='Client'  >
            <SelectSearchInput
              onChange={(v) => onChange('client', v)}
              onSearch={(value) => onSearch(value)}
              data={clients}
              label='prenom'
              label2='nom'
              label3='telephone'
              valueLabel='id'
              placeholder="Selectionnez le client"
            />
          </Form.Item>
          <Form.Item label='Devise'>
            <SelectSearchInput
              onChange={(v) => onChange('devise', v)}
              onSearch={(value) => onSearch(value)}
              data={devises}
              label='code'
              valueLabel='id'
              placeholder="Selectionnez la devise"
            />
          </Form.Item>
        </Form>
      </Row>
      <br />
      <br />
      <Row gutter={[16, 16]}>
        <Col span={12} >
          <Card
            style={{ marginTop: 16 }}
            title={<h4 className="text-center">Crédits aux clients</h4>}
          >
            <Table
              columns={columns as any}
              rowKey={(record: any) => record.login}
              dataSource={dataCredit}
              pagination={{ pageSize: 100 }}
              loading={loading}
            // onChange={handleTableCreditsChange}
            />
            <Row justify='end'>
              {
                Object.entries(sumCredits).map(
                  (val, index) => <Col span={6}>
                    <Form.Item label={`Total en ${val[0]}`} >
                      <InputNumber
                        disabled
                        value={val[1] as number}
                        style={{ width: '100%', fontSize: 15, fontStyle: "bold" }}
                        formatter={value => `${parseInt(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                        parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                      />
                    </Form.Item>
                  </Col>
                )
              }
            </Row>
          </Card>
        </Col>

        <Col span={12} >
          <Card
            style={{ marginTop: 16 }}
            title={<h4 className="text-center">Dettes des clients</h4>}
          >
            <Table
              columns={columns as any}
              rowKey={(record: any) => record.login}
              dataSource={dataDette}
              pagination={{ pageSize: 100 }}
              loading={loading}
            // onChange={handleTableDettesChange}
            />
            <Row justify='end'>
              {
                Object.entries(sumDettes).map(
                  (val, index) => <Col span={6}>
                    <Form.Item label={`Total en ${val[0]}`} >
                      <InputNumber
                        disabled
                        value={val[1] as number}
                        style={{ width: '100%', fontSize: 15 }}
                        formatter={value => `${parseInt(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                        parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                      />
                    </Form.Item>
                  </Col>
                )
              }
            </Row>
          </Card>
        </Col>

      </Row>

      <Row>
        <Col span={24} >
          <Card
            style={{ marginTop: 16 }}
            type="inner"
            title={<h4 className="text-center">Les montants disponible nette par devise</h4>}
          >
            <Row >
              <Col span={4}>
                <p>Disponible net: </p>
              </Col>
              {
                Object.entries(dispoNet).map(
                  (val, index) => <Col span={4}>
                    <Form.Item label={`Total en ${val[0]}`} >
                      <InputNumber
                        disabled
                        value={val[1] as number}
                        style={{ width: '100%', fontSize: 20 }}
                        formatter={value => `${parseInt(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                      />
                    </Form.Item>
                  </Col>
                )
              }
            </Row>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col span={24} >
          <Card
            style={{ marginTop: 16 }}
            type="inner"
            title={<h4 className="text-center">Disponibles caisse</h4>}
          >
            <Row >
              <Col span={4}>
                <p>Disponible caisse: </p>
              </Col>
              {
                Object.entries(dispoCaisse).map(
                  (val, index) => <Col span={4}>
                    <Form.Item label={`Total en ${val[0]}`} >
                      <InputNumber
                        disabled
                        value={val[1] as number}
                        style={{ width: '100%', fontSize: 20 }}
                        formatter={value => `${parseInt(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                      />
                    </Form.Item>
                  </Col>
                )
              }
            </Row>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col span={24} >
          <Card
            style={{ marginTop: 16 }}
            type="inner"
            title={<h4 className="text-center">Montant total en GNF</h4>}
          >
            <Row >
              <Col span={6}>
                <p>Montant total disponible en GNF: </p>
              </Col>
              {
                  <Col span={6} >
                    <Form.Item >
                      <InputNumber
                        disabled
                        value={montantTotalGNF as number}
                        style={{ width: '100%', fontSize: 20, }}
                        formatter={value => `${parseInt(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + " GNF"}
                        parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                      />
                    </Form.Item>
                  </Col>
              }
            </Row>
          </Card>
        </Col>
      </Row>

    </Card>
  </>
}

export default Rapport;