import React from 'react';
import axios from "../services/axios"
import moment from 'moment'
import { Card, Row, Col, Form, Input, Tag, Table } from 'antd'
import DatePickerCustom from '../utils/components/datePicker';
import _ from 'lodash';

const SoldesBureau: React.FC = () => {

  const [data, setData] = React.useState([])
  const [dataCopy, setDataCopy] = React.useState([])
  const [datas, setDatas] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [sumOutByDevise, setSumOutByDevise] = React.useState([])
  const [sumEnterByDevise, setSumEnterByDevise] = React.useState([])
  const [form] = Form.useForm();

  React.useEffect(() => {
    getCompteEntreprise()
  }, [])


  const getCompteEntreprise = () => {
    axios.get('/comptes_entreprises/soldes')
      .then(response => {
        
        setLoading(false)
        setData(response.data);
        setDataCopy(response.data);
        setDatas(response.data);

      });
  }

  const columns = [
    {
      title: "N°",
      key: "index",
      render: (row: any, value: any, index: number = 0) => (
        <>
          {index + 1}
        </>
      ),
    },
    {
      title: 'Devise',
      dataIndex: ['devise', 'code'],
      key: 'code',
      render: (text: any, record: any) => <a >{text}</a>,
    },
    {
      title: 'Montant',
      dataIndex: 'solde',
      align: 'right',
      key: 'solde',
      render: (montant: any, record: any) => (
        <>
          <Tag style={{ fontSize: 18, float:"right" }} >
            {parseFloat(montant).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ' + record.devise.code}
          </Tag>
          { }
        </>
      ),
    },
  ]


  const getSoldeBetweenTwoDate = (start: any, end: any) => {
    setLoading(true)
    axios.get(`factures/rapport/sommes-in/by-devise/${start}/${end}`)
      .then(response => {
        
        setSumEnterByDevise(response.data)
        axios.get(`factures/rapport/sommes-out/by-devise/${start}/${end}`)
          .then(res => {
            
            setSumOutByDevise(res.data)
            let diff = sumObjectsByKey(response.data, res.data);
            
            if (_.size(diff) !== 0) {
              const dataConvert = Object.assign({}, datas);
              const diffConvert = Object.assign({}, diff);
              const arrayData = _.map(dataConvert, (val, code, id) => {
                return { [val['devise']['code']]: val['solde'] };
              });
              let element
              element = { ...arrayData[0], ...arrayData[1], ...arrayData[2], ...arrayData[3] };
 
              const result: any = sumObjectsByKeyAndConvertArray(element, diffConvert)

              setData(result);
            }
            else {
              setData(dataCopy)
            }
            setLoading(false)
          });

      });
  }

  function sumObjectsByKey(ob1: any, ob2: any) {
    let sum: any = {};
    Object.keys(ob1).forEach(key => {
      if (ob2.hasOwnProperty(key)) {
        sum[key] = ob1[key] - ob2[key]
      }
    })
    return sum;
  }

  function sumObjectsByKeyAndConvertArray(ob1: any, ob2: any) {
    let sum: any = {};
    Object.keys(ob1).forEach(key => {
      if (ob2.hasOwnProperty(key)) {
        sum[key] = ob1[key] - ob2[key]
      }
    })
    const array = _.map(sum, (val, id) => {
      return { solde: val, devise: { code: id } };
    });
    return array;
  }


  const sumArray = (data: any[]) => {
    let sumSoldeByDevise = 0
    let result = []
    let devise = []
    for (let i = 0; i < data.length; i++) {
      let a = data[i]
      if (devise.indexOf(a.devise.code) == -1) {
        devise.push(a.devise.code)
        data.forEach(item => {
          if (item.devise.code == a.devise.code) {
            sumSoldeByDevise += item.solde
          }
        })
        var n = a.devise.code;
        result.push({
          ['solde']: sumSoldeByDevise,
          ['id']: a.id,
          ['devise_id']: a.devise_id,
          ['description']: a.description,
          ['devise']: a.devise,
          ['created_at']: a.created_at,
          ['updated_at']: a.updated_at,
        });
      }
      sumSoldeByDevise = 0
    }
    return result;
  }


  const onDateChange = (dates: any, dateStrings: any) => {
    
    const dateJour = new Date();
    const dateFormated = moment(dateJour).format('YYYY-MM-DD');
    getSoldeBetweenTwoDate(dateStrings[0], dateFormated)
  }

  function disabledDate(current: any) {
    return current && current > moment().endOf('day');
  }

  const dj = new Date()
  return <>
    <Card
      style={{ marginTop: 16 }}
      type="inner"
      title={<h4 className="text-center">LES SOLDES DU BUREAU</h4>}
    >
      <Row justify='end'>
        <Form form={form} layout='inline'>
          <Form.Item label="Dates" >
            <DatePickerCustom
              disabledDate={disabledDate}
              onChange={onDateChange}
            />
          </Form.Item>
        </Form>
      </Row>
      <br />
      <br />
      <Row>
        <Col span={16} offset={4}  >
          <Table
            columns={columns as any}
            dataSource={data}
            loading={loading}
          />
        </Col>
      </Row>
    </Card>
  </>
}

export default SoldesBureau;