import React from 'react';
import { Button, Modal, Form, Input, InputNumber, Card, DatePicker, Col, Row, Space } from 'antd';
import { useParams, Redirect } from 'react-router-dom'
import SelectSearchInput from '../../utils/components/selectSearchInput';
import axios from '../../services/axios'
import moment from 'moment';

export default function Edit() {
    const [form] = Form.useForm();
    const { id }: any = useParams()
    const [devises, setDevises] = React.useState([])
    const [clients, setClients] = React.useState([])
    const [depense, setDepense] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [redirect, setRedirect] = React.useState(false)
    const [operation, setOperation] = React.useState({})
    React.useEffect(() => {
        if (id) {
            getSingleDepense(id)
        }
        getDevise()
        getClient()
    }, [])

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    };

    const getDevise = () => {
        axios.get('/devises')
            .then(response => {
                
                setDevises(response.data);
                setLoading(false)
            });
    }
    const onReset = () => {
        form.resetFields();
    }
    const config = {
        rules: [{ type: 'object' as const, required: true, message: 'Please select time!' }],
    };

    const getClient = () => {
        axios.get('/clients')
            .then(response => {
                
                setClients(response.data);
                setLoading(false)
            });
    }

    const onChange = (name: string, value: any) => {
        form.setFieldsValue({ [name]: value })
    }
    const getSingleDepense = (id: number) => {
        axios.get(`/depenses/${id}`)
            .then(response => {
                setOperation(response.data)
                setDepense(response.data)
                form.setFieldsValue({ 'numero_recu': response.data.numero_recu })
                form.setFieldsValue({ 'date_depense': moment(response.data.date_depense) })
                form.setFieldsValue({ 'montant': response.data.montant })
                form.setFieldsValue({ 'devise_id': response.data.devise.devise_id })
                form.setFieldsValue({ 'montant_debiter': response.data.montant_debiter })
                form.setFieldsValue({ 'motif': response.data.motif })
                form.setFieldsValue({ 'type_operation': response.data.type_operation })
            })
            .catch(error => {
                console.log('error not found', error);
            });
    }
    const onSearch = (val: any) => {
    }
    const updateDepense = (data: any[], id: number) => {
        axios.put(`/depenses/edit/${id}`, data)
            .then(function (response) {
                
                setRedirect(true)
                form.resetFields();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const onFinish = (values: any) => {
        setLoading(true);
        
        updateDepense(values, id)
        setLoading(false);
    };

    const typeCompteDebiter = [
        { id: 'bureau', prenom: "", nom: 'Compte Bureau', telephone: "" },
        { id: 'globale', prenom: "", nom: 'Compte Globale', telephone: "" }
    ]

    const op = operation as any
    return (
        <>
            <Row>
                <Col span={12} offset={6}>
                    <Card size="small" title={`Modification d'une ${op.type_operation}`}  >
                        <Form
                            form={form}
                            layout="vertical"
                            name="nest-messages"
                            onFinish={onFinish}
                            validateMessages={validateMessages}
                        >
                            <Form.Item label="Numero reçu" style={{ marginBottom: 0 }} rules={[{ required: true }]}>
                                <Form.Item
                                    name='numero_recu'
                                    style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                                >
                                    <Input
                                        placeholder="N° reçu "
                                    />
                                </Form.Item>
                                <Form.Item name='date_depense' style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}  >
                                    <DatePicker format="YYYY-MM-DD" defaultValue={moment()} placeholder="date" style={{ width: '100%' }} />
                                </Form.Item>
                            </Form.Item>

                            <Form.Item name='montant' label="Montant" rules={[{ required: true }]}>
                                <InputNumber
                                    placeholder="Montant"
                                    style={{ width: '100%' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>

                            <Form.Item name='devise_id' label="Devise" rules={[{ required: true }]}>
                                <SelectSearchInput
                                    onChange={(v) => onChange('devise', v)}
                                    onSearch={(value) => onSearch(value)}
                                    label='code'
                                    valueLabel='id'
                                    data={devises}
                                    placeholder="Selectionnez la devise "
                                />
                            </Form.Item>
                            <Form.Item
                                name='compte_debite'
                                rules={[{ required: true }]}
                                label="Compte à debiter"
                            >
                                <SelectSearchInput
                                    onChange={(v) => onChange('compte_debite', v)}
                                    data={[...clients, ...typeCompteDebiter]}
                                    label='prenom'
                                    label2='nom'
                                    label3='telephone'
                                    valueLabel='id'
                                    onSearch={(value) => onSearch(value)}
                                    placeholder="sectionnez le compte à debiter"
                                />
                            </Form.Item>
                            <Form.Item name='motif' label="Modif" rules={[{ required: true }]}>
                                <Input.TextArea />
                            </Form.Item>

                            <Form.Item initialValue={op.type_operation} name='type_operation' noStyle>
                                <Input type="hidden" />
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 8 }} >
                                <Space>
                                    <Button loading={loading} type="primary" htmlType="submit">
                                        Modifier
                                </Button>
                                    <Button htmlType="button" onClick={onReset}>
                                        Annuler
                            </Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col >
            </Row >
            {
                redirect ? (<Redirect to={op.type_operation == "depense" ? `/depenses` : "/inject-fond"} />) : ''
            }
        </>
    );
}
