import React from 'react';
import { Link, Redirect, useParams } from 'react-router-dom'
import { Table, Tag, Space, Card, Popconfirm, Spin, Row, Col, Form, DatePicker, Select, InputNumber, Button, Pagination } from 'antd';
import { LoadingOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import FormModal from '../components/modalDepotForm'
import FormClientModal from '../../customers/components/FormModal';
import axios from '../../services/axios'
import SelectSearchInput from '../../utils/components/selectSearchInput';
import DatePickerCustom from '../../utils/components/datePicker';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface IProps {
  pagination: {
    pageSize: Number,
    current: Number
  }
}

const Depot = () => {
  const { id }: any = useParams()
  const [visible, setVisible] = React.useState(false);
  const [visibleClient, setVisibleClient] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [redirect, setRedirect] = React.useState(false)
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 100
  });
  const [loading, setLoading] = React.useState(false);
  const [devises, setDevises] = React.useState([])
  const [clients, setClients] = React.useState([])
  const [sumDepotByDevise, setSumDepotByDevise] = React.useState({})
  const [defaultValue, setDefaultValue] = React.useState({})
  const [dateStart, setDateStart] = React.useState(moment(new Date()).format('YYYY-MM-DD'));
  const [dateEnd, setDateEnd] = React.useState(moment(new Date()).format('YYYY-MM-DD'));
  const [idDevise, setIdDevise] = React.useState(null)

  const [form] = Form.useForm();

  React.useEffect(() => {
    const dateJour = new Date();
    const dateFormated = moment(dateJour).format('YYYY-MM-DD');

    fetchData({ pagination } as IProps);
    getDevise()
    getClient()
    getSumDepotByDevise(dateFormated, dateFormated)
  }, [])

  const getRandomuserParams = (params: any) => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
  });
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    fetchData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  const getsetClient = () => {
    setVisibleClient(true)
  }

  const getClient = () => {
    axios.get('/clients')
      .then(response => {
        
        setClients(response.data);
        setLoading(false)
      });
  }

  const fetchData = (params: any) => {
    setLoading(true);
    const dateJour = new Date();
    const dateFormated = moment(dateJour).format('YYYY-MM-DD');
    axios.get(`/operations/type/depot/${dateFormated}/${dateFormated}`)
      .then(response => {
        // const dataPagination = getRandomuserParams(params)
        
        setData(response.data)
        setLoading(false)
        // setPagination({ ...dataPagination.pagination, total: 20 })
      });
  };

  const getDevise = () => {
    axios.get('/devises')
      .then(response => {
        
        setDevises(response.data);
        setLoading(false)
      });
  }

  const getSumDepotByDevise = (start: any, end: any) => {
    axios.get(`/operations/rapport/sum-depot/by-devise/${start}/${end}`)
      .then(response => {
        
        setSumDepotByDevise(response.data);
        setLoading(false)
      });
  }

  const getSingleOperation = (id: number) => {
    setVisible(true);
    axios.get(`/operations/${id}`)
      .then(response => {
        form.setFieldsValue(response.data)
        setDefaultValue(response.data)
        setLoading(false)
      })
      .catch(error => {
        console.log('error not found', error);
      });
  }


  const onCreate = (values: any) => {
    const dateJour = new Date();
    const dateFormated = moment(dateJour).format('YYYY-MM-DD');
    setConfirmLoading(true);
    axios.post('/operations/new', values)
      .then(function (response) {
        
        setLoading(false);
        setRedirect(true)
        fetchData(Pagination)
        getSumDepotByDevise(dateFormated, dateFormated)
        setConfirmLoading(false);
        setVisible(false);
        form.resetFields();
      })
      .catch(function (error) {
        console.log(error);
        setConfirmLoading(false);
        setLoading(false);
      });

  };

  const onClientCreate = (values: any) => {
    axios.post('/clients/new', values)
      .then(function (response) {
        getClient()
        
        setLoading(false);
        form.resetFields();
        setVisibleClient(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const onChange = (name: string, value: any) => {
    form.setFieldsValue({ [name]: value })
    setIdDevise(value);
    setLoading(true)
    axios.get(`/operations/type/depot/${dateStart}/${dateEnd}/${value}`)
      .then(response => {
        
        setData(response.data)
        setLoading(false)
      });

  }
  function onSearch(val: string) {
  }
  const handleOk = (id: number) => {
    setLoading(true)
    // setConfirmLoading(false);
    axios.delete(`/operations/delete/${id}`)
      .then(response => {
        
        axios.get(`/operations/type/depot/${dateStart}/${dateEnd}`)
          .then(response => {
            // const dataPagination = getRandomuserParams(params)
            
            setData(response.data)
            getSumDepotByDevise(dateStart, dateEnd)
            setLoading(false)
            // setPagination({ ...dataPagination.pagination, total: 20 })
          });
        setLoading(false)
        // setConfirmLoading(true)
      });

  };

  const handleCancel = () => {
  };

  const renderCustomCell = (item: any, name: string) => {
    if (name == 'client') {
      const { client } = item;
      const clients = client.prenom + ' ' + client.nom + '/' + client.telephone
      return clients;
    }
    else if (name == 'devise') {
      const { devise } = item
      return devise.code
    }
  };

  let i = 0;
  const columns = [
    {
      title: "N°",
      key: "index",
      render: (row: any, value: any, index: number = 0) => (
        <>
          {index + 1}
        </>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date_operation',
      key: 'date_operation'
    },
    {
      title: 'N° reçu',
      dataIndex: 'numero_recu',
      key: 'numero_recu'
    },
    {
      title: 'Client',
      key: 'client',
      render: (record: any,) => renderCustomCell(record, 'client'),
    },
    {
      title: 'Déposant',
      key: 'nom_deposant',
      render: (nom_deposant: number, row: any) => (
        <>
          {row.nom_deposant ? (row.nom_deposant + ' ' + row.tel_deposant) : 'Par le client lui méme'}
        </>
      ),
    },
    {
      title: 'Motif',
      dataIndex: 'motif',
      key: 'motif',
    },
    {
      title: 'Montant',
      dataIndex: 'montant',
      align: 'right',
      key: 'montant',
      render: (montant: any, record: any) => (
        <>
          <Tag >
            {parseFloat(montant).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ' + record.devise.code}
          </Tag>
          { }
        </>
      ),

    },
    {
      title: 'Taux',
      dataIndex: 'taux',
      align: 'center',
      key: 'taux',
      render: (montant: any, row: any) => (
        <>
          <Tag >
            {parseFloat(montant).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          </Tag>
        </>
      ),
    },
    {
      title: 'Montant Converti',
      dataIndex: 'montant_sortie',
      align: 'right',
      key: 'montant_sortie',
      render: (montant: any, row: any) => (
        <>
          <Tag color='blue' >
            {parseFloat(montant).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ' + row.devisesortie.code}
          </Tag>
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: number) => (
        <>
          {
            status == 1 ? (
              <Tag color='success' >
                validé
              </Tag>
            ) : (
              <Tag color='warning' >
                en attente
              </Tag>
            )
          }
        </>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: any, row: any) => (
        <>
          <Button title="Editer">
            <Link style={{ marginRight: 10 }} to={`/depot/edit/${id}`}><EditOutlined /></Link>
          </Button>
          <Popconfirm
            disabled={row.status !== 1 ? false : true}
            title="Etes vous sûre de supprimer cette ligne?"
            onConfirm={() => handleOk(id)}
            okText={(<><Spin spinning={confirmLoading} indicator={antIcon} />     OUI </>)}
            cancelText='Non'
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleCancel}
          >
            <Button title="Supprimer" type="primary" danger ><DeleteOutlined /></Button>
          </Popconfirm>
        </>
      )
    },

  ];

  const onDateChange = (dates: any, dateStrings: any) => {
    setLoading(true)
    setDateStart(dateStrings[0])
    setDateEnd(dateStrings[1])
    if (idDevise) {
      axios.get(`/operations/type/depot/${dateStrings[0]}/${dateStrings[1]}/${idDevise}`)
        .then(response => {
          
          setData(response.data)
          setLoading(false)
        });
    }
    else {
      axios.get(`/operations/type/depot/${dateStrings[0]}/${dateStrings[1]}`)
        .then(response => {
          
          setData(response.data)
          setLoading(false)
        });
    }

    getSumDepotByDevise(dateStrings[0], dateStrings[1])
  }

  return <>
    <Card size="small" title={<h4>Liste des Dépots</h4>} extra={<Button
      type="primary"
      onClick={() => {
        setVisible(true);
      }}
    >
      Nouveau
    </Button>} >
      <Row justify='end'>
        <Form layout='inline'>
          <Form.Item label="Dates">
            <DatePickerCustom
              onChange={onDateChange}
            />
          </Form.Item>
          <Form.Item label='Devise'>
            <SelectSearchInput
              onChange={(v) => onChange('devise', v)}
              onSearch={(value) => onSearch(value)}
              data={devises}
              label='code'
              valueLabel='id'
              placeholder="Selectionnez la devise d'entre"
            />
          </Form.Item>
        </Form>
      </Row>
      <br />
      <br />

      <Table
        columns={columns as any}
        rowKey={(record: any) => record.login}
        dataSource={data}
        pagination={{ pageSize: 100 }}
        loading={loading}
      // onChange={handleTableChange}
      />
      <Row justify='end'>
        {
          Object.entries(sumDepotByDevise).map(
            (val, index) => <Col span={4}>
              <Form.Item label={`Total en ${val[0]}`} >
                <InputNumber
                  disabled
                  value={val[1] as number}
                  style={{ width: '100%', fontSize: 15 }}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
          )
        }
      </Row>
      <FormModal
        visible={visible}
        setClientVisible={getsetClient}
        onCreate={onCreate}
        devises={devises}
        clients={clients}
        defaultVal={defaultValue}
        loading={confirmLoading}
        onCancel={() => {
          setVisible(false);
        }}
      />

      <FormClientModal
        visible={visibleClient}
        onCreate={onClientCreate}
        onCancel={() => {
          setVisibleClient(false);
        }}
      />
    </Card>
    {
      redirect ? (<Redirect to="/depot/" />) : ''
    }
  </>;
}

export default Depot;